import { ModalBase } from "../modal_base";

export class ListFilterSaveModal extends ModalBase {
    DOM = {};

    constructor() {
        super('list_filter_save_modal');
    }

    setupDOM($modalTriggerBtn, additionalData) {
        this.DOM.remodal_saved_filter_name = $('#saved_filter_name');
    }

    setupModalEvents($modalTriggerBtn, additionalData) {
        this.MODAL_CONTAINER_EL.find('.modal__btn-primary').unbind('click').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.DOM.remodal_saved_filter_name.removeClass('error');

            // Get a name for our saved search
            var filterName = this.DOM.remodal_saved_filter_name.val();
            if (filterName.trim() === '') {
                this.DOM.remodal_saved_filter_name.addClass('error');
                return;
            }

            ajaxSavedFilters(filterName);
        });
        this.MODAL_CONTAINER_EL.find('input').off('keydown').on('keydown', (e) => {
            if(e.keyCode === 13) {
                this.MODAL_CONTAINER_EL.find('.modal__btn-primary').trigger('click');
            }
        });
    }
}